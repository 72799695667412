import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { LiveDataService } from 'src/services/LiveDataService';

@Component({
  selector: 'vex-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit, OnDestroy {

  @Input() customTemplate: TemplateRef<any>;

  constructor(private liveDataService: LiveDataService) {}

  dataBalance: any;

  ngOnInit() {
  }

  ngOnDestroy(): void {}
}
