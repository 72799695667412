import { Component, Input, OnInit } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { LayoutService } from '../../services/layout.service';
import { ConfigService } from '../../config/config.service';
import { map, startWith, switchMap } from 'rxjs/operators';
import { NavigationLink } from '../../interfaces/navigation-item.interface';
import { PopoverService } from '../../components/popover/popover.service';
import { Observable, of } from 'rxjs';
import { UserMenuComponent } from '../../components/user-menu/user-menu.component';
import { MatDialog } from '@angular/material/dialog';
import { SearchModalComponent } from '../../components/search-modal/search-modal.component';
import { LocalStoreService } from 'src/services/local-store.service';
import { ApiService } from 'src/services/ApiServices';
import { LiveDataService } from 'src/services/LiveDataService';

@Component({
  selector: 'vex-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss']
})
export class SidenavComponent implements OnInit {

  @Input() collapsed: boolean;
  collapsedOpen$ = this.layoutService.sidenavCollapsedOpen$;
  title$ = this.configService.config$.pipe(map(config => config.sidenav.title));
  imageUrl$ = this.configService.config$.pipe(map(config => config.sidenav.imageUrl));
  showCollapsePin$ = this.configService.config$.pipe(map(config => config.sidenav.showCollapsePin));
  userVisible$ = this.configService.config$.pipe(map(config => config.sidenav.user.visible));
  searchVisible$ = this.configService.config$.pipe(map(config => config.sidenav.search.visible));

  name:string
  email:string

  userMenuOpen$: Observable<boolean> = of(false);

  items = this.navigationService.items;
  public balance:number
  type:any

  constructor(private navigationService: NavigationService,
              private layoutService: LayoutService,
              private configService: ConfigService,
              private localStorage: LocalStoreService,
              private readonly popoverService: PopoverService,
              private apiService: ApiService,
              private liveDataService: LiveDataService,
              private readonly dialog: MatDialog) { }

  ngOnInit() {


    
    this.name = this.localStorage.getItem('name')
    this.email = this.localStorage.getItem('email')
    this.type = this.localStorage.getItem('type');
    if(this.type === 'payer'){
      this.items = [
        {
          type: 'subheading',
          label: 'Menu',
          children: [
            {
              type: 'link',
              label: 'Dashboard',
              route: '/',
              icon: 'mat:insights',
              routerLinkActiveOptions: { exact: true }
            }
          ]
        },
        {
          type: 'dropdown',
          label: 'Remmitances',
          icon: 'mat:attach_money',
          children: [
            {
              type: 'link',
              label: 'List',
              route: '/admin/remesas'
            }
          ]
        },
        {
          type: 'dropdown',
          label: 'Help Center',
          icon: 'mat:contact_support',
          children: [

            {
              type: 'link',
              label: 'FAQ',
              route: '/admin/help-center/faq'
            }
          ]
        },
      ];
    }else{
      this.requestBalance()
    }

  }

  collapseOpenSidenav() {
    this.layoutService.collapseOpenSidenav();
  }

  collapseCloseSidenav() {
    this.layoutService.collapseCloseSidenav();
  }

  toggleCollapse() {
    this.collapsed ? this.layoutService.expandSidenav() : this.layoutService.collapseSidenav();
  }

  trackByRoute(index: number, item: NavigationLink): string {
    return item.route;
  }

  requestBalance(){
    this.apiService.requestBalance()
    .subscribe({
      next: response => {
        if(response.data!=null){
          this.balance = response.data.balance;
          this.liveDataService.updateData(this.balance);
          
        }
      },
      error: error => {
        console.error('Error:', error);
      }
    });
  }

  openProfileMenu(origin: HTMLDivElement): void {
    this.userMenuOpen$ = of(
      this.popoverService.open({
        content: UserMenuComponent,
        origin,
        offsetY: -8,
        width: origin.clientWidth,
        position: [
          {
            originX: 'center',
            originY: 'top',
            overlayX: 'center',
            overlayY: 'bottom'
          }
        ]
      })
    ).pipe(
      switchMap(popoverRef => popoverRef.afterClosed$.pipe(map(() => false))),
      startWith(true),
    );
  }

  openSearch(): void {
    this.dialog.open(SearchModalComponent, {
      panelClass: 'vex-dialog-glossy',
      width: '100%',
      maxWidth: '600px'
    });
  }
}
