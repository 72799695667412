<mat-nav-list>
  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon matListItemIcon svgIcon="logo:gmail"></mat-icon>
    <div matListItemTitle>Send with Gmail</div>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon matListItemIcon svgIcon="logo:facebook"></mat-icon>
    <div matListItemTitle>Post on Facebook</div>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon matListItemIcon svgIcon="logo:twitter"></mat-icon>
    <div matListItemTitle>Share on Twitter</div>
  </a>

  <a (click)="close()" [routerLink]="[]" mat-list-item>
    <mat-icon matListItemIcon svgIcon="logo:whatsapp"></mat-icon>
    <div matListItemTitle>Share on WhatsApp</div>
  </a>
</mat-nav-list>
