import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LocalStoreService } from "./services/local-store.service";

@Injectable({
  providedIn: 'root'
})
export class ConstantServiceProvider {
  server = 'https://api-bankremesas.linkapps.online:8085/' + 'api/v1/';
  //server = 'http://localhost:8085/' + 'api/v1/';
  encript = "experoagency2021panelangular";
  serverImages = "https://experco.agency/ExpercoApi/files/images/"
  urlEncuestas = "https://mimenu.experco.agency/#/habitacion/"
  encriptClient = "expercoagencydevelop2021clientesencriptados";
  constructor(private localStorage:LocalStoreService) {}

  getHeadersImage() {
    let httpHeaders = new HttpHeaders()
      .set('Authorization', 'Basic YWRtaW5pbmlzdHJhZG9yU29mdGNydWQyMDIwOmFkbWluaXN0cmFkb3Jzb2Z0Y3J1ZDUyNzUyMTk5NA==')
      .set('Access-Control-Allow-Origin', '*');
    let options = {
      headers: httpHeaders
    };
    return options;
  }

  getHeadersWithToken() {
    let token = this.localStorage.getItem('token');
    console.log(token)
    let httpHeaders = new HttpHeaders()
      .set('Access-Control-Allow-Headers', 'Content-Type')
      .set('Access-Control-Allow-Origin', '*')
      .set('Authorization', 'Bearer '+token)
      .set('Content-Type', 'application/json');
    let options = {
      headers: httpHeaders
    };
    return options;
  }

  getHeaders() {
    let httpHeaders = new HttpHeaders()
      .set('Access-Control-Allow-Headers', 'Content-Type')
      .set('Access-Control-Allow-Origin', '*')
      .set('Content-Type', 'application/json');
    let options = {
      headers: httpHeaders
    };
    return options;
  }

}
