import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CustomLayoutComponent } from './custom-layout/custom-layout.component';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';


const routes: Routes = [
  {
    path: 'admin',
    component: CustomLayoutComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./dashboard-analytics/dashboard-analytics.module').then(m => m.DashboardAnalyticsModule),
      },
      {
        path: 'remesas',
        loadChildren: () => import('./admin/remesas/remesas.module').then(m => m.RemesasModule)
      },
      {
        path: 'help-center',
        loadChildren: () => import('./admin/help-center/help-center.module').then(m => m.HelpCenterModule)
      },
    ]
  },
  {
    path: 'ticket',
    loadChildren: () => import('./ticket/ticket.module').then(m => m.TicketModule),
  },
  {
    path: '',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: QuicklinkStrategy,
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled'
  })],
  exports: [RouterModule,QuicklinkModule]
})
export class AppRoutingModule {
}
