import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConstantServiceProvider } from '../constant-service';
import { LocalStoreService } from './local-store.service';
import { CommonAlerts } from '../services/common-alerts';
import { Remmitance } from 'src/models/remmitance-model';


@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(public httpClient: HttpClient, private constantServiceProvider: ConstantServiceProvider, private localStorage:LocalStoreService,private handleErrors: CommonAlerts) {
  }


  loginAdmin(user: any, agent: any, password: any): Observable<any> {
    var param = {
      email: user,
      password: password,
      agent: agent
    };
    let body = JSON.stringify(param);
    return this.httpClient.post(this.constantServiceProvider.server + "users/login" , body,this.constantServiceProvider.getHeaders()).pipe(
      //catchError(this.handleErrors.handleError)
    );
  }

  getCatalogBanks(): Observable<any> {
    return this.httpClient.get(this.constantServiceProvider.server+'catalogs/banks',this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  getCatalogBranches(): Observable<any> {
    return this.httpClient.get(this.constantServiceProvider.server+'catalogs/branches',this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  getCatalogBranchesByCountry(type:string,country:string): Observable<any> {
    return this.httpClient.get(this.constantServiceProvider.server+'catalogs/branches/'+type+'/byCountry/'+country,this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  requestAddRemittance(body:Remmitance): Observable<any> {
    return this.httpClient.post(this.constantServiceProvider.server+'remittances/add',body,this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  getCatalogRates(): Observable<any> {
    return this.httpClient.get(this.constantServiceProvider.server+'catalogs/rates',this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  getAllRemittances(): Observable<any> {
    return this.httpClient.get(this.constantServiceProvider.server+'remittances',this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  requestBalance(): Observable<any> {
    return this.httpClient.get(this.constantServiceProvider.server+'balances/findByIdBranch',this.constantServiceProvider.getHeadersWithToken())
  }

  requestPayRemittance(hash_remmitance:string): Observable<any> {
    return this.httpClient.post(this.constantServiceProvider.server+'remittances/pay/'+hash_remmitance,this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }

  requestSendRemittance(hash_remmitance:string): Observable<any> {
    return this.httpClient.post(this.constantServiceProvider.server+'remittances/send/'+hash_remmitance,this.constantServiceProvider.getHeadersWithToken())
    .pipe(
      catchError((error) => {
        return this.handleErrors.handleError(error);
      })
    );
  }


}
